.App {
  text-align: center;
}

body {
  background-color: rgba(240, 240, 240, 0.2);
}

.bold {
  font-weight: bold;
}

.sidebar-active {
  font-weight: bold;
  /*color: darkblue;*/
}

$header-height: 5rem;
.header {
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #008B00;
  color: white;
  font-size: 2em;
  font-weight: bold;
  border-bottom: 2px solid rgba(64, 64, 64, 0.5);
  margin-bottom: 1em;
}

.brand {
  font-style: italic;
  margin: 0 1em;
}

.brand-img {
  margin-left: 1em;
  width: 2em;
}

.cart-button {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 2.5em;
  padding-left: 2em;
  font-size: 1rem;
  border-radius: 1em 1em;
  flex-grow: 0;
  margin-right: 2em;
}

.cart-button img {
  margin-left: 1em;
  width: 1.5em;
}

.cart-button-quantity {
  margin-left: 1em;
  padding-right: 2em;
}

.main {
  display: grid;
  grid-template-columns: 12em 1fr;
}

.sidebar-list {
  background-color: #ffffff;
  list-style: none;
  text-align: left;
  margin: 0 0;
  padding: 0 0;
}

.sidebar-list:first-child {
  border-top: 1px solid rgba(200, 200, 200, 0.2);
}

.sidebar-item {
  font-size: 1.0em;
  padding: 1em 0.5em;
  color: #505050;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.sidebar-item a {
  color: #505050;
}

.sidebar-item a:visited {
  color: #505050;
}

.aisle-items {
  display: block;
  padding: 0.5em 1em;
}

.aisle-items:last-child {
  padding-bottom: 0;
}

.aisle-item {
  list-style: none;
  padding: 0 0 0.5em 1em;
}

.items {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 1em;
}

.item {
  display: inline-block;
  width: 20em;
  height: 24em;
  font-size: 0.8em;
  border: unset;
}

.item-img {
  width: 20em;
  height: 15em;
}

.item img {
  width: 80%;
  margin-left: 0.8em;
}

.item-content {
  width: 80%;
  margin-left: 1.2em;
  margin-top: 1em;
  text-align: left;
}

.item-price {
  padding: 0.2em 1em;
  font-size: 1em;
  font-weight: 600;
}

.item-name {
  display: block;
  padding: 0.2em 1rem;
  height: 4em;
  color: #808080;
}

button.item-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  right: 0.8em;
  /*bottom: 3.5em;*/
  bottom: 30%;
  font-size: 2em;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #008B00;
  color: #008B00;
  background-color: #fff;
  border-radius: 50% 50%;
}

button.item-button:hover {
  background-color: #eee;
}

button.item-button:focus {
  outline: unset;
}

.button-span {
  font-weight: 300;
  margin-top: -0.2em;
}

.quantity-container {
  position: absolute;
  background-color: #ffffff;
  font-size: 2em;
  bottom: 30%;
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  width: 90%;
  margin-left: 5%;
}

.minus, .plus {
  color: #008B00;
  display: flex;
  justify-content: space-around;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.7em;
}

.minus img {
  width: 30%;
  margin: auto;
}

.plus {
  border: 1px solid dodgerblue;
  border-radius: 2px;
  background-color: #f9f9f9;
}

.quantity-container .minus, .quantity-container .quantity {
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 2px;
}

.department-button {
  border: unset;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.department-button .rotate {
  transform: rotateZ(90deg);
}

.department-button:focus {
  outline: unset;
}

.modal-dialog.item-modal {
  width: 80%;
  margin-left: 10%;
  max-width: unset;
}

.modal-row1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.modal-img {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-description {
  position: absolute;
  width: 80%;
  top: 0;
  left: 10%;
}

.modal-name {
  font-size: 1.6em;
  font-weight: 600;
  color: #444444;
}

.modal-price {
  font-size: 1.6em;
  font-weight: 600;
  color: black;
}

.square img {
  width: 90%;
  margin-left: 5%;
}

.modal-form-row {
  display: grid;
  width: 90%;
  margin-left: 0;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 72px;
}

.modal-form-update-row {
  display: grid;
  width: 90%;
  margin-left: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 72px;
}

.quantity-column-1 {
  position: relative;
}

.modal-quantity {
  margin: 0 2em 0 0;
}

.modal-quantity-select, .modal-incart-select, .modal-update-select {
  height: 40px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
  font-size: 1.0em;
}

.modal-quantity-input-group {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-quantity-input {
  width: 90%;
}

.modal-button-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-button-row button {
  height: 40px;
}

.modal-incart-group {
  grid-column-start: 1;
  grid-column-end: 3;
}

.modal-incart-group select {
  background-color: green;
  color: white;
}

.modal-update-group {
  margin-right: 1em;
}

.modal-sections {
  padding-left: 2em;
}

.modal-section-container {
  margin-bottom: 2em;
  position: relative;
}

.hide {
  display: none;
}

.left-button {
  position: absolute;
  left: 0em;
  top: 6em;
  width: 3em;
  height: 3em;
  background-color: rgba(212, 212, 212, 1);
  border-radius: 50% 50%;
  text-align: center;
  vertical-align: middle;
}

.right-button {
  position: absolute;
  right: 0em;
  top: 6em;
  width: 3em;
  height: 3em;
  background-color: rgba(212, 212, 212, 1);
  border-radius: 50% 50%;
  text-align: center;
  vertical-align: middle;
}

span.helper {
  height: 100%;
}

.modal-section-container .fa-chevron-left {
  vertical-align: middle;
  font-size: 2em;
  font-weight: lighter;
  position: absolute;
  top: 0.28em;
  left: 0.25em;
  color: #888;
}

.modal-section-container .fa-chevron-right {
  vertical-align: middle;
  font-size: 2em;
  font-weight: lighter;
  position: absolute;
  top: 0.28em;
  right: 0.25em;
  color: #888;
}

.modal-section-container .fa-chevron-left::before {
}

.modal-section {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
}

.modal-section .item {
  flex-shrink: 0;
}

.modal-section::-webkit-scrollbar {
  -webkit-appearance: none;
  /*width: 2px;*/
  height: 4px;
}

.modal-section::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: rgba(128,128,128,0.5);
  -webkit-box-shadow: 0 1px 0 0 rgba(128,128,128,0.3);
}

/*.modal-section::-webkit-scrollbar::after {*/
/*  content: '>'*/
/*}*/

.backdrop {
  position: absolute;
  left: 0;
  top: $header-height;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.cart-panel {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  //width: 500px;
  width: 0;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  animation: open-cart-panel 1s forwards;
}

@keyframes open-cart-panel {
  from { width: 0 }
  to { width: 500px }
}

.cart-panel-header {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 2em;
  font-weight: bold;
  height: $header-height;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #f0f0f0;
}

.cart-panel-header span {
  margin: auto;
}

.cart-panel-close {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  border-radius: 10px 10px;
  background-image: url("/images/close.png");
  background-size: cover;
}

.cart-item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  padding: 0.5em 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

.cart-item-img img {
  width: 90%;
  margin-left: 10%;
}

.cart-item-content {
  display: flex;
  align-items: center;
}

.cart-item .quantity-container {
  font-size: 1.4em;
  top: 1em;
  bottom: unset;
  right: 15%;
  width: 20%;
  //height: 2.1em;
}

.cart-item-button {
  margin-top: 1em;
  border-radius: 4px 4px;
  background-color: white;
  text-align: center;
  width: 3em;
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.cart-item-button:hover {
  background-color: #f0f0f0;
}

.cart-item-price {
  //display: flex;
  //align-items: center;
  padding-top: 1em;
  //justify-content: space-around;
  text-align: right;
  padding-right: 0.5em;
}
